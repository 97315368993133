import { Button, Form, Icon, Input, notification } from 'antd'
import { Dispatch } from 'easy-peasy'
import * as React from 'react'
import { connect } from 'react-redux'
import { Redirect, RouteChildrenProps } from 'react-router'
import styled from 'styled-components'
import { AppRoutes } from '../AppRoutes'
import { LoginRequest } from '../DTO/BaseApiDTO'
import Logo from '../img/logo-dark.svg'
import { IApplicationModel, IApplicationState } from '../store/store'
import polyglot from '../Translator'

const LoginContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const openNotificationWithIcon = (title, message) => {
  notification.error({
    message: title,
    description: message
  })
}

interface IProps extends IStateProps, IDispatchProps, RouteChildrenProps {
  history: any
  form: any
}

export const LoginLink = styled.p`
  font-size: 14px;
  color: #29c086;
  cursor: pointer;
  margin-top: 15px;
`

const Login = (props: IProps) => {
  async function onClickLogin(e) {
    try {
      const values = await handleSubmit(e)
      await props.login(values)
    } catch (error) {
      openNotificationWithIcon(
        "Erreur d'authentification",
        'Veuillez vérifier que vous avez entré le bon courriel et mot de passse.'
      )
    }
  }

  const goToSignUp = () => props.history.push(AppRoutes.SIGNUP)

  const handleSubmit = (e): Promise<LoginRequest> => {
    e.preventDefault()
    return new Promise((resolve, reject) => {
      props.form.validateFields((err, values) => {
        if (!err) {
          resolve(values)
        } else {
          reject(err)
        }
      })
    })
  }

  const { getFieldDecorator } = props.form

  let redirectTo = AppRoutes.MY_DIAGRAMS

  if (props.location && props.location.state && props.location.state.from && props.location.state.from.pathname) {
    redirectTo = props.location.state.from.pathname
  }

  return !props.isLoggedIn ? (
    <LoginContainer>
      <img src={Logo} style={{ width: 500, height: 'auto' }} alt="" />
      <Form onSubmit={handleSubmit} style={{ width: '300px', marginTop: '100px' }}>
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [
              {
                required: true,
                message: polyglot.t('provideEmail')
              }
            ]
          })(
            <Input
              prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder={polyglot.t('email')}
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [
              {
                required: true,
                message: polyglot.t('providePassword')
              }
            ]
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder={polyglot.t('password')}
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button type="primary" style={{ width: '100%' }} htmlType="submit" onClick={onClickLogin}>
            {polyglot.t('connection')}
          </Button>
          <br />
          <div
            style={{
              textAlign: 'center'
            }}
          >
            <LoginLink onClick={goToSignUp}>{polyglot.t('firstUse')}</LoginLink>
            {/* <LoginLink>{polyglot.t('forgotPassword')}</LoginLink> */}
          </div>
        </Form.Item>
      </Form>
    </LoginContainer>
  ) : (
    <Redirect to={redirectTo} />
  )
}

interface IStateProps {
  isLoggedIn: boolean
}
interface IDispatchProps {
  login: (user) => void
}

const mapStateToProps = (state: IApplicationState): IStateProps => ({
  isLoggedIn: !!state.user.user
})

const mapDispatchToProps = (dispatch: Dispatch<IApplicationModel>): IDispatchProps => ({
  login: user => dispatch.user.logIn(user)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'normal_login' })(Login))
